const Button = ({
  text,
  bgColor,
  fontColor,
  textTransform,
  padding,
  customFont,
  fontSize,
  style,
  type,
  disabled,
  handleClick,
  className = "rounded-[17px] lg:rounded-[20px]",
}: any) => <div>
    <button
      className={`w-full ${className} ${fontSize} ${customFont} ${padding} ${textTransform} ${fontColor} ${disabled ? "bg-disable" : "bg-hover"
        } `}
      style={style}
      type={type ? type : "button"}
      disabled={disabled}
      onClick={handleClick}
    >
      {text}
    </button>
  </div>

export default Button
