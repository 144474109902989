import axios from 'axios'

export type BaseData = {
    online: boolean
    performance: number | null
    earnings: number | null
    location: LocationData | null
    satelliteInfo: SatelliteInfo | null
    dop: DOPInfo | null
    recentEarning: number | null
    satellites: Map<string, SatelliteData>
}

type LocationData = {
    latitude: number
    longitude: number
    altitude: number
}

type SatelliteInfo = {
    tracked: number
    inUse: number
}

type DOPInfo = {
    gdop: number
    pdop: number
    hdop: number
    vdop: number
}

export type SatelliteData = {
    pos: SatelliteMeasurement | null
    snr: SatelliteSNR | null
}

type SatelliteMeasurement = {
    az: number
    el: number
}

type SatelliteSNR = {
    l1: number
    l2: number
    l5: number
}

export const BaseService = new (class {
    getBase(name: string): Promise<BaseData> {
        return new Promise<BaseData>((resolve, reject) => {
            axios.get<BaseData>(`${process.env.REACT_APP_MIDDLEWARE_ENDPOINT}/hotspots/${name}`)
                .then(response => resolve(response.data))
                .catch(error => reject(error))
        })
    }
})()
