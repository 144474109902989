import { initializeApp } from "firebase/app"
import { getFirestore, collection, addDoc } from "firebase/firestore"

// Production
const firebaseConfig = {
	apiKey: "AIzaSyDofKz92s2tPucrowAudlcih3EWSRKLY1A",
	authDomain: "rtk-direct.firebaseapp.com",
	projectId: "rtk-direct",
	storageBucket: "rtk-direct.appspot.com",
	messagingSenderId: "245347989927",
	appId: "1:245347989927:web:9b2d83ee5b0e9d5d845e82",
	measurementId: "G-M9LG66XX40"
}

// Staging
// const firebaseConfig = {
// 	apiKey: "AIzaSyAtSAUAL9fGVXiJ-pVisHSbMw4qxVzbklw",
// 	authDomain: "rtk-direct-staging.firebaseapp.com",
// 	projectId: "rtk-direct-staging",
// 	storageBucket: "rtk-direct-staging.appspot.com",
// 	messagingSenderId: "520598026881",
// 	appId: "1:520598026881:web:d7de64daf6acc11a8b6b80",
// 	measurementId: "G-DXE4SJ8CP3"
// }

const useFirebaseApp = () => {
	const app = initializeApp(firebaseConfig)
	return app
}

const recordActivity = async (text: string, user?: string) => {
	const app = initializeApp(firebaseConfig)
	const db = getFirestore(app)

	await addDoc(collection(db, "activities"), {
		text,
		timestamp: Date.now(),
		user
	})
}

export default useFirebaseApp
export { recordActivity }