import logo from "../assets/images/logo.png"
import MobileSidebar from "./MobileSidebar"

type SidebarProps = {
    isSidebarOpen: boolean
    toggleSidebar: () => void
    children: any
    blackBackground?: boolean
}

const Sidebar = ({ isSidebarOpen, toggleSidebar, children, blackBackground }: SidebarProps) => <aside
    className={`overflow-hidden sidebar-custom-height fixed inset-y-0 left-0 z-30 w-full lg:w-[20%] xl:w-[22%] lg:ml-[1%] lg:my-[20px] lg:mr-[1%] transition-transform duration-300 transform
              ${!!blackBackground ? 'custom-sidebar-style-rtk' : 'custom-sidebar-style'}
              ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"} lg:translate-x-0 lg:left-0
              ${isSidebarOpen ? "lg:translate-y-0" : "-translate-y-full"} lg:translate-y-0`}>
    <div className="custom-z h-full overflow-auto">
        <div className="hidden lg:flex lg:flex-row lg:justify-start lg:items-center lg:gap-x-3 xl:gap-x-4 lg:pl-3 xl:pl-7 py-9">
            <a href="/">
                <img src={logo} alt="logo" className="lg:w-[25px] xl:w-[30px]" />
            </a>
            <p className="uppercase lg:text-[14px] xl:text-[20px]">
                RTK
                <span className="gradient-text ml-2 custom-font-500 ">Direct Cloud</span>
            </p>
        </div>
        <div className="mt-[50px] lg:mt-0 hidden lg:block">
            {children}
        </div>
        <div className="block lg:hidden bg-black custom-z">
            <MobileSidebar toggleSidebar={toggleSidebar} />
        </div>
    </div>
</aside>

export default Sidebar
