import { useEffect, useRef } from "react"
import ReactECharts from "echarts-for-react"

type BarChartProps = {
  data: BarChartData[]
}

export type BarChartData = {
  name: string
  L1: number
  L2: number
  L3: number
}

const BarChart = ({ data }: BarChartProps) => {
  const chartRef: any = useRef(null)

  useEffect(() => {
    if (chartRef.current) {
      const myChart = chartRef.current.getEchartsInstance()

      const xData = data?.map((item: any) => item.name)
      const seriesData = [
        {
          name: "L1",
          type: "bar",
          barGap: 0,
          emphasis: {
            focus: "series",
          },
          data: data?.map((item: any) => item.L1),
          itemStyle: {
            color: "#F1446F",
          },
        },
        {
          name: "L2",
          type: "bar",
          emphasis: {
            focus: "series",
          },
          data: data?.map((item: any) => item.L2),
          itemStyle: {
            color: "#3D8C34",
          },
        },
        {
          name: "L3",
          type: "bar",
          emphasis: {
            focus: "series",
          },
          data: data?.map((item: any) => item.L3),
          itemStyle: {
            color: "orange",
          },
        },
      ]

      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          data: ["L1", "L2", "L3"],
          bottom: 0,
          itemWidth: 12,
          itemHeight: 12,
          icon: "rect",
          textStyle: {
            color: "#FFF",
            fontFamily: "Poppins",
            fontSize: 12,
            fontWeight: 400,
          },
        },
        grid: {
          left: "10%",
          right: "4%",
          bottom: "15%",
          top: "45px",
          containLabel: true,
          borderColor: "#303030",
          show: false,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: xData,
            axisLabel: {
              show: false,
              color: "#FFF",
              fontFamily: "Poppins",
              fontSize: 10,
              fontWeight: 400,
              margin: 15,
            },
            splitLine: {
              show: false, // Show grid lines for the x-axis
              lineStyle: {
                color: "#303030", // Grid line color
              },
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#FFF",
                width: 0.5,
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              color: "#FFF",
              fontFamily: "Poppins",
              fontSize: 10,
              fontWeight: 400,
              margin: 10,
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#FFF",
                width: 0.5,
              },
            },
            splitLine: {
              show: false, // Show grid lines for the x-axis
              lineStyle: {
                color: "#303030", // Grid line color
              },
            },
            axisTick: {
              show: false,
            },
            offset: 20,
          },
        ],
        series: seriesData, // Use the series data we created earlier
      }

      myChart.setOption(option)
    }
  }, [data])
  return <div>
    <ReactECharts ref={chartRef} option={{}} style={{ height: "270px" }} />
  </div>
}

export default BarChart
