import React, {createContext, useEffect, useState} from "react";
import "./App.css";
import Config from "./config/Config";
import {CacheProvider} from "@emotion/react";
import createEmotionCache from "./services/utilities/createEmotionCache";
import useFirebaseApp from "./services/hooks/useFirebaseApp";
import {Auth, browserLocalPersistence, getAuth, onAuthStateChanged, setPersistence, User} from "firebase/auth";

const clientSideEmotionCache = createEmotionCache();

export interface AppContextType {
    fireBaseAuth: Auth| null
    fireBaseUser: User| null
}

export const AppContext = createContext<AppContextType | undefined>(undefined);

function useAppContext(): AppContextType {
    const app = useFirebaseApp()
    const auth = getAuth(app)
   const [user, setUser] = useState<User|null>(null);

    setPersistence(auth, browserLocalPersistence);
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
            } else {
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, [auth]);

    return {fireBaseUser: user, fireBaseAuth: auth}
}

function App(props: any) {
    const {emotionCache = clientSideEmotionCache} = props;
    const context = useAppContext();

    return (
        <div className="App">
            <CacheProvider value={emotionCache}>
                <AppContext.Provider value={context}>
                    <Config/>
                </AppContext.Provider>
            </CacheProvider>
        </div>
    );
}

export default App;
