import { useCallback, useEffect, useState } from "react"

class Loading {
}

export class Error {
    readonly error: any

    constructor(error: any) {
        this.error = error
    }
}

type PromiseLoaderProps<Type> = {
    loader: Type | Loading | Error
    loading?: any
    error: ((error: Error) => any) | any
    loaded: (a: Type) => any
}

export type Loader<Type> = Type | Loading | Error

export const usePromiseLoader/*: <Type extends Object>(promise: () => Promise<Type>) => Type | Loading | Error*/ = <Type extends Object>(promise: () => Promise<Type>) => {
    const [loader, setLoader] = useState<Type | Loading | Error>(new Loading())

    const getData = useCallback(() => {
        promise()
            .then((response) => {
                setLoader(response)
            })
            .catch((error) => {
                setLoader(new Error(error))
            })
    }, [promise])

    useEffect(() => {
        getData()
    }, [getData])
    return { loader, getData }
}

export function usePromiseFunctionalLoader<Type extends Object, InType>(input: (InType | undefined), promise: (input: InType) => Promise<Type>, validation?: (input: InType) => boolean) {
    const [loader, setLoader] = useState<Type | Loading | Error>(new Loading())
    const getData = useCallback(() => {
        if (!input) {
            console.trace("Bypassed usePromiseLoader input because it was undefined")
            return
        }
        if (validation && !validation(input)) {
            console.trace("Bypassed usePromiseLoader input because it failed validation")
            return
        }
        promise(input)
            .then((response) => {
                setLoader(response)
            })
            .catch((error) => {
                setLoader(new Error(error))
            })
    }, [input, promise, validation])


    useEffect(() => {
        getData()
    }, [getData])
    return { loader, getData }
}

const PromiseLoader = <Type extends Object>({ loader, loading, error, loaded }: PromiseLoaderProps<Type>) => {
    switch (loader.constructor) {
        case Loading:
            if (loading === undefined) return <div className="loader"></div>
            return loading
        case Error:
            if (typeof error === 'function') {
                return error((loader as Error).error)
            }
            return error
        default:
            return loaded(loader as Type)
    }
}

export default PromiseLoader
