export const ConnectionParameterService = new (class {
    getIp(): string {
        return process.env.REACT_APP_STATICS_IP || "0.0.0.0"
    }

    getPort(): string {
        return process.env.REACT_APP_STATICS_PORT || "2101"
    }

    getDNS(): string {
        return process.env.REACT_APP_STATICS_DNS || "unavailable"
    }
})()
