import { useContext } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import logo from "../assets/images/logo.png"
import Button from "./common/Button"
import { URLGenerationService } from "../services/URLGenerationService"
import { AppContext } from "../App"

type MainTopBarProps = {
    toggleSidebar: any
    isSidebarOpen: boolean
    toggleSearch?: Function
}

const MainTopbar = (props: MainTopBarProps) => {
    const {
        toggleSidebar,
        isSidebarOpen,
        toggleSearch
    } = props

    const navigate = useNavigate()
    const location = useLocation()

    const loggedIn = useContext(AppContext)?.fireBaseUser

    return <div>
        <header
            className={`block lg:hidden fixed top-0 left-0 w-full py-6 px-4 md:px-8 ${isSidebarOpen
                    ? "mobile-custom-topbar-style-z"
                    : "mobile-custom-topbar-style"
                }`}>
            <div className="flex justify-between items-center">
                {/* Topbar content */}
                <div className="flex gap-x-4">
                    <button
                        onClick={toggleSidebar}
                        className="text-white focus:outline-none lg:hidden">
                        {isSidebarOpen ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="27"
                                height="27"
                                viewBox="0 0 27 27"
                                fill="none">
                                <path
                                    d="M21.375 13.5L5.625 13.5"
                                    stroke="white"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round" />
                                <path
                                    d="M12.375 20.25L5.625 13.5L12.375 6.75"
                                    stroke="white"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round" />
                            </svg>
                        ) : (
                            <svg
                                className="w-6 h-6"
                                fill="white"
                                stroke="currentColor"
                                viewBox="0 0 24 24">
                                {/* Hamburger icon */}
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                        )}
                    </button>
                    <div className="flex flex-row justify-center items-center gap-x-4">
                        <a href="/">
                            <img src={logo} alt="logo" className="w-[25px]" />
                        </a>
                        <p className="uppercase text-[18px]">
                            RTK
                            <span className="gradient-text ml-2 text-[18px] custom-font-500">
                                Direct Cloud
                            </span>
                        </p>
                    </div>
                </div>
                {/* search bar */}
                {toggleSearch !== undefined ? (
                    <div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            className="cursor-pointer"
                            onClick={() => toggleSearch()}>
                            <path
                                d="M11 2C15.968 2 20 6.032 20 11C20 15.968 15.968 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2ZM11 18C14.8675 18 18 14.8675 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18ZM19.4853 18.0711L22.3137 20.8995L20.8995 22.3137L18.0711 19.4853L19.4853 18.0711Z"
                                fill="white"
                            />
                        </svg>
                    </div>
                ) : ("")}
                {/* Topbar menus */}
            </div>
        </header>
        {/* Topbar for laptop */}
        <header
            className="hidden lg:block custom-topbar-style fixed top-0 left-0 w-full lg:w-[76%] xl:w-[74%] z-10 lg:ml-[22.5%] xl:ml-[24.5%] md:mt-[25px] md:mr-[1%] h-auto topbar-map-custom-z">
            <div className="flex justify-between items-baseline lg:px-4 xl:px-5 2xl:px-8">
                <ul className="flex justify-center items-center lg:gap-x-3.5 xl:gap-x-4 2xl:gap-x-6">
                    <li>
                        <Link
                            to={URLGenerationService.walletPageURL}
                            className={`hover:text-white lg:text-[13px] xl:text-[15px] 2xl:text-[16px] py-[22px] custom-font-400 ${location.pathname === URLGenerationService.walletPageURL
                                    ? "gradient-text border-b border-[#4AFC2D]"
                                    : "text-white border-b border-transparent"
                                }`}>
                            Wallet
                        </Link>
                    </li>
                    <li>
                        <div className="h-[18px] w-[1px] custom-line"></div>
                    </li>
                    <li>
                        <Link
                            to={URLGenerationService.hotspotsPageURL}
                            className={`hover:text-white lg:text-[13px] xl:text-[15px] 2xl:text-[16px] py-[22px] custom-font-400 ${location.pathname === URLGenerationService.hotspotsPageURL
                                    ? "gradient-text border-b border-[#4AFC2D]"
                                    : "text-white border-b border-transparent"
                                }`}>
                            My Hotspots
                        </Link>
                    </li>
                    <li>
                        <div className="h-[18px] w-[1px] custom-line"></div>
                    </li>
                    <li>
                        <Link
                            to={URLGenerationService.useRTKPageURL}
                            className={`hover:text-white lg:text-[13px] xl:text-[15px] 2xl:text-[16px] py-[22px] custom-font-400 ${location.pathname === URLGenerationService.useRTKPageURL
                                    ? "gradient-text border-b border-[#4AFC2D]"
                                    : "text-white border-b border-transparent"
                                }`}>
                            Use RTK
                        </Link>
                    </li>
                    <li>
                        <div className="h-[18px] w-[1px] custom-line"></div>
                    </li>
                    <li>
                        <Link
                            to={URLGenerationService.useRinexPageURL}
                            className={`hover:text-white lg:text-[13px] xl:text-[15px] 2xl:text-[16px] py-[22px] custom-font-400 ${location.pathname === URLGenerationService.useRinexPageURL
                                    ? "gradient-text border-b border-[#4AFC2D]"
                                    : "text-white border-b border-transparent"
                                }`}>
                            Use RINEX
                        </Link>
                    </li>
                    <li>
                        <div className="h-[18px] w-[1px] custom-line"></div>
                    </li>
                    <li>
                        <Link
                            to={URLGenerationService.mainPageURL}
                            className={`hover:text-white lg:text-[13px] xl:text-[15px] 2xl:text-[16px] py-[22px] custom-font-400 ${location.pathname === URLGenerationService.mainPageURL
                                    ? "gradient-text border-b border-[#4AFC2D]"
                                    : "text-white border-b border-transparent"
                                }`}>
                            Map
                        </Link>
                    </li>
                </ul>
                <div className="flex justify-center items-center lg:gap-x-2 xl:gap-x-3 py-2">
                    {/* below two0 buttons will show when no login */}
                    {!loggedIn ? (
                        <>
                            <a
                                href={URLGenerationService.logInPageURL}
                                className="text-[12px] lg:text-[12px] xl:text-[16px] 2xl:text-[18px] text-white hover:text-light-green pr-[10px] custom-font-400">
                                Log in
                            </a>
                            <a href={URLGenerationService.signUpPageURL}>
                                <Button
                                    text="Create Account"
                                    bgColor="bg-gradient-to-r from-bg-green1 to-bg-green2"
                                    fontColor="text-[#0D0D0D]"
                                    textTransform=""
                                    padding="py-[15px] xl:py-[13px] 2xl:py-[13px] px-4 xl:px-8"
                                    customFont="custom-font-400"
                                    fontSize="text-[12px] lg:text-[12px] xl:text-[14px] 2xl:text-[15px]"
                                    className="rounded-[17px] lg:rounded-[20px]" />
                            </a>
                        </>
                    ) : (
                        <>
                            <div>
                                <button
                                    className="bg-carbon-black2 hover:bg-gradient-to-r from-bg-green1 to-bg-green2 text-white hover:text-[#0D0D0D] py-[14px] px-3 lg:py-[13px] xl:py-[13px] 2xl:py-[13px] xl:px-6 custom-font-400 text-[12px] lg:text-[12px] xl:text-[14px] 2xl:text-[15px] border border-[#4AFC2D] hover:border-transparent rounded-[17px] xl:rounded-[20px]"
                                    onClick={() => {
                                        navigate(URLGenerationService.hotspotsPageURL + "?connect=true")
                                    }}>
                                    Connect a Hotspot
                                </button>
                            </div>
                            {process.env.REACT_APP_DEVELOPMENT && <div>
                                <Button
                                    text="Connect your wallet !TODO"
                                    bgColor="bg-gradient-to-r from-bg-green1 to-bg-green2"
                                    fontColor="text-[#0D0D0D]"
                                    textTransform=""
                                    padding="py-[15px] px-3 lg:py-[13px] xl:py-[13px] 2xl:py-[13px] xl:px-6"
                                    customFont="custom-font-400"
                                    fontSize="text-[12px] lg:text-[12px] xl:text-[14px] 2xl:text-[15px]"
                                    className="rounded-[17px] xl:rounded-[20px]" />
                                {/*handleClick={() => open()}*/}
                            </div>}
                            <a
                                href={URLGenerationService.basicInfoPageURL}
                                className="user-custom-button px-[12px] py-[12px] xl:py-[12px] 2xl:py-[12.5px] xl:px-[16px]">
                                {loggedIn.photoURL ?
                                    <img src={loggedIn.photoURL} alt="Profile" width="33" height="33" /> :
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none">
                                        <g clipPath="url(#clip0_1_19464)">
                                            <path
                                                d="M20 22H18V20C18 19.2044 17.6839 18.4413 17.1213 17.8787C16.5587 17.3161 15.7956 17 15 17H9C8.20435 17 7.44129 17.3161 6.87868 17.8787C6.31607 18.4413 6 19.2044 6 20V22H4V20C4 18.6739 4.52678 17.4021 5.46447 16.4645C6.40215 15.5268 7.67392 15 9 15H15C16.3261 15 17.5979 15.5268 18.5355 16.4645C19.4732 17.4021 20 18.6739 20 20V22ZM12 13C11.2121 13 10.4319 12.8448 9.7039 12.5433C8.97595 12.2417 8.31451 11.7998 7.75736 11.2426C7.20021 10.6855 6.75825 10.0241 6.45672 9.2961C6.15519 8.56815 6 7.78793 6 7C6 6.21207 6.15519 5.43185 6.45672 4.7039C6.75825 3.97595 7.20021 3.31451 7.75736 2.75736C8.31451 2.20021 8.97595 1.75825 9.7039 1.45672C10.4319 1.15519 11.2121 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7C18 8.5913 17.3679 10.1174 16.2426 11.2426C15.1174 12.3679 13.5913 13 12 13ZM12 11C13.0609 11 14.0783 10.5786 14.8284 9.82843C15.5786 9.07828 16 8.06087 16 7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7C8 8.06087 8.42143 9.07828 9.17157 9.82843C9.92172 10.5786 10.9391 11 12 11Z"
                                                fill="url(#paint0_linear_1_19464)" />
                                        </g>
                                        <defs>
                                            <linearGradient
                                                id="paint0_linear_1_19464"
                                                x1="5.32"
                                                y1="4.07759"
                                                x2="21.0125"
                                                y2="5.01291"
                                                gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#4AFC2D" />
                                                <stop offset="1" stopColor="#1EA707" />
                                            </linearGradient>
                                            <clipPath id="clip0_1_19464">
                                                <rect width="24" height="24" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>}
                            </a>
                        </>
                    )}

                    {/* below three buttons will show when user is logged in */}
                </div>
            </div>
        </header>
    </div>

}

export default MainTopbar
